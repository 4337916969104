body {
  color: #e6e2e7;
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.toolbar {
  width: 100%;
  height: 80px;
  background-color: #f08285;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex: 1;
  width: 95%;
}

.priceListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.priceContainer {
  width: 150px;
  height: 100px;
  background-color: #d7dce0;
  color: #00426b;
  border-radius: 12px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 26px -6px rgba(37,41,72,1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.down {
  background-color: #f08285;
  color: #fff;
}

.resting {
  background-color: #00426b;
  color: #fff;
}